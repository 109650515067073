import { Typography, Grid } from '@material-ui/core'; // , List, ListItem, ListItemIcon, ListItemText
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
// import { Link } from "react-router-dom";

import Page from '../Components/Layout/Page';

const WebinarPageMessages = defineMessages({
    pagesMediaSectionPartnersTitle: {
        id: 'PAGES_WEBINAR_TITLE',
        defaultMessage: 'Administrace Kam s ním?',
    },
});

const WebinarPage: React.FC = () => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Page currentPage="webinar">
            <Typography className={ classes.title } variant="h4" component="h1">
            { intl.formatMessage(WebinarPageMessages.pagesMediaSectionPartnersTitle) }
            </Typography>
            <br />
            <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/ftQtGxm_dHo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

        </Page>
    );
};

export default WebinarPage;

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(3),
    },
    root: {

    },
    img: {
        margin: theme.spacing(1),
    },
}));
