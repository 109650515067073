import { IconButton, Box } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { observer } from 'mobx-react-lite';
import React from 'react';

import UserButton from '../domain/login/components/UserButton';
import { useStores } from '../Stores/useStores';

import CategoryMenu from './CategoryMenu/CategoryMenu';
import TopMenu from './TopMenu/TopMenu';

import LegendButton from './Common/LegendButton';
import LocateUserButton from './Common/LocateUserButton';
import MapTypeButton from './Common/MapTypeButton';
import ShareButton from './Common/ShareButton';

const MapControls: React.FC = () => {
    const { layoutStore, mapStore, appStore } = useStores();
    const classes = useStyles({
        isMobile: layoutStore.isMobile,
    });
	appStore.check();

    const onZoomInClick = () => {
        mapStore.zoomIn();
    };

    const onZoomOutClick = () => {
        mapStore.zoomOut();
    };

    return (
        <div className={ classes.rootTop }>
        <div className={ classes.root }>
            <div className={ classes.left }>
                { /* 
                <FilterButton className={classes.button} /> 
                <Box boxShadow={ 3 } className={ classes.placeCategoryHolder }>
                    <TopMenu />
                </Box>
                */ }
                
            </div>
            <div className={ classes.right }>
                <div className={ classes.top }>
                    { false && !layoutStore.isMobile && !appStore.isApp && (
                        <div className={ classes.button }>
                            <UserButton />
                        </div>
                    ) }
                    <Box boxShadow={ 3 } className={ `${classes.button} ${classes.zoomControl}` }>
                        <IconButton
                            color="inherit"
                            aria-label="zoom in"
                            onClick={ onZoomInClick }
                        >
                            <ZoomInIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="zoom out"
                            onClick={ onZoomOutClick }
                        >
                            <ZoomOutIcon />
                        </IconButton>
                    </Box>
                    <LocateUserButton type="fab" className={ classes.button } />
                    <LegendButton fab className={ classes.button } />
                    <MapTypeButton className={ classes.button } />
                </div>
                
            </div>
            
        </div>
        <div className={ classes.root }>
            <div className={ classes.bottom }>
                    { layoutStore.isMobile && (
                        <ShareButton fab className={ classes.button } />
                    ) }
                    { !layoutStore.isMobile && (
                        <Box boxShadow={ 3 } className={ classes.placeCategoryHolder }>
                            <CategoryMenu />
                        </Box>
                    ) }
            </div>
        </div>
    </div>
    );
};

export default observer(MapControls);

const useStyles = makeStyles((theme: Theme) => createStyles({
    rootTop: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        paddingTop: params.isMobile ? theme.spacing(1) : theme.spacing(1),
    }),
    root: (params: any) => ({
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        
    }),
    left: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    top: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        flexGrow: 1,
        paddingRight: theme.spacing(params.isMobile ? 1 : 1.5),
    }),
    bottom: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flexGrow: 1,
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(params.isMobile ? 1 : 1.5),
    }),
    button: {
        pointerEvents: 'all',
        margin: theme.spacing(0, 0, 1.5, 0),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    zoomControl: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderRadius: '24px',
        shadow: theme.shadows[24],
    },
    placeCategoryHolder: {
        borderRadius: theme.spacing(1),
        overflow: 'hidden',
        border: '1px solid white',
        marginLeft: '8px',
    },
}));
